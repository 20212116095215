import {
    LOGIN_DISABLED,
    RETRY_LIMIT,
    SSO_SESSION_RETRY_COUNTER,
    SSO_SESSION_REPLY,
    LOGIN_REQUIRED,
    LOGIN_OK,
    INTERACTION_REQUIRED,
    SSO_SESSION_RETRY_TYPE,
    REFRESH,
    SSO_SESSION_STATE,
    SSO_SESSION_CURRENT_SCOPES,
    SSO_SESSION_SESSION_SCOPE,
    SSO_SESSION_INTERACTION_REQUIRED,
    PROFILE,
    LIVE_INTERACTION,
    NO_INTERACTION,
    CHILD_LOGIN_CODE,
    INTENTION,
} from '../util/Constants';

const clientId = () => ((window.VRT || {}).SSOConfig || {}).clientId;
const sessionSet = (key, value) => {
    sessionStorage.setItem(`${clientId()}_${key}`, value);
};
const sessionGet = key => sessionStorage.getItem(`${clientId()}_${key}`);
const sessionDel = (key) => {
    sessionStorage.removeItem(`${clientId()}_${key}`);
};
const localSet = (key, value) => {
    localStorage.setItem(`${clientId()}_${key}`, value);
};
const localGet = key => localStorage.getItem(`${clientId()}_${key}`);
const localDel = (key) => {
    localStorage.removeItem(`${clientId()}_${key}`);
};

export const storeProfile = (profile) => {
    if (profile) {
        localSet(PROFILE, profile);
    } else {
        localDel(PROFILE);
    }
};
export const retrieveProfile = () => localGet(PROFILE);
export const deleteProfile = () => localDel(PROFILE);

export const getLiveInteraction = () => JSON.parse(localGet(LIVE_INTERACTION));
export const setLiveInteraction = liveInteraction => localSet(LIVE_INTERACTION, JSON.stringify(liveInteraction));
export const clearLiveInteraction = () => localDel(LIVE_INTERACTION);

export const setChildLoginCode = loginCode => localSet(CHILD_LOGIN_CODE, JSON.stringify(loginCode));
export const getChildLoginCode = () => JSON.parse(localGet(CHILD_LOGIN_CODE));
export const clearChildLoginCode = () => localDel(CHILD_LOGIN_CODE);

export const setLoginIntention = intention => sessionSet(INTENTION, String(intention));
// eslint-disable-next-line no-bitwise
export const getLoginIntention = () => sessionGet(INTENTION) | 0;
export const clearLoginIntention = () => sessionDel(INTENTION);

/**
 * @param {string} key
 * @returns {function(string): function(): boolean}
 */
const checkSession = key => value => () => {
    const reply = sessionGet(key);
    return reply === value;
};

/**
 * @type {function(string): function(): boolean}
 */
const checkSessionReply = checkSession(SSO_SESSION_REPLY);

/**
 * @type {function(): boolean}
 */
const isLoginRequired = checkSessionReply(LOGIN_REQUIRED);

/**
 * @type {function(): boolean}
 */
const isInteractionRequired = checkSessionReply(INTERACTION_REQUIRED);

/**
 * @type {function(): boolean}
 */
export const isLoginDisabled = checkSessionReply(LOGIN_DISABLED);

/**
 * @type {function(): boolean}
 */
export const isRetryRefresh = checkSession(SSO_SESSION_RETRY_TYPE)(REFRESH);

/**
 * @returns {boolean}
 */
const manualActionRequired = () => isLoginDisabled() || isInteractionRequired() || isLoginRequired();

/**
 * @returns {boolean}
 */
export const hasSession = () => !!sessionGet(SSO_SESSION_REPLY);

/**
 * @returns {string}
 */
export const getSession = () => sessionGet(SSO_SESSION_REPLY);

export const setRetryRefresh = () => sessionSet(SSO_SESSION_RETRY_TYPE, REFRESH);

export const setSessionToOk = () => sessionSet(SSO_SESSION_REPLY, LOGIN_OK);

/**
 * @param {string|null} [error]
 */
export const setSessionToError = (error) => {
    if (error) {
        sessionSet(SSO_SESSION_REPLY, error);
    } else {
        sessionSet(SSO_SESSION_REPLY, 'not logged in');
    }
};

/**
 * @returns {number}
 */
export const getSessionState = () => {
    const state = parseInt(sessionGet(SSO_SESSION_STATE), 10);
    return Number.isNaN(state) ? 0 : state;
};

/**
 * @param {number} state
 */
export const setSessionState = state => sessionSet(SSO_SESSION_STATE, String(state));

export const clearSessionState = () => sessionDel(SSO_SESSION_STATE);

/**
 * @param {string} scopes
 */
export const setSessionCurrentScopes = (scopes) => {
    if (scopes) {
        sessionSet(SSO_SESSION_CURRENT_SCOPES, scopes);
    } else {
        sessionSet(SSO_SESSION_CURRENT_SCOPES, '');
    }
};

/**
 * @returns {string}
 */
export const getSessionCurrentScopes = () => sessionGet(SSO_SESSION_CURRENT_SCOPES) || '';

/**
 * @returns {string}
 */
export const getSessionScope = () => sessionGet(SSO_SESSION_SESSION_SCOPE);

/**
 * @param {string} scope
 */
export const setSessionScope = scope => sessionSet(SSO_SESSION_SESSION_SCOPE, scope);

export const clearSessionScope = () => sessionDel(SSO_SESSION_SESSION_SCOPE);

export const clearSession = () => sessionDel(SSO_SESSION_REPLY);

export const setSessionInteractionRequired = scope => sessionSet(SSO_SESSION_INTERACTION_REQUIRED, scope);

export const getSessionInteractionRequired = () => sessionGet(SSO_SESSION_INTERACTION_REQUIRED);

export const clearSessionStorage = () => {
    // clearing other values does not seem to be needed - but name of function does not make clear that we ignore the others
    sessionDel(SSO_SESSION_REPLY);
    sessionDel(SSO_SESSION_RETRY_COUNTER);
};

export const startRetryCounterIfNeeded = () => {
    if (!sessionGet(SSO_SESSION_RETRY_COUNTER)) {
        sessionSet(SSO_SESSION_RETRY_COUNTER, '0');
    }
};

// eslint-disable-next-line no-undef
export const noInteraction = () => globalThis.VRT.SSOController[NO_INTERACTION] === true;
// eslint-disable-next-line no-undef
export const setNoInteraction = () => { globalThis.VRT.SSOController[NO_INTERACTION] = true; };

/**
 * @returns {boolean}
 */
export const shouldRetry = () => {
    const counter = parseInt(sessionGet(SSO_SESSION_RETRY_COUNTER), 10);

    if (Number.isNaN(counter) || counter >= RETRY_LIMIT || manualActionRequired()) {
        return false;
    }
    sessionSet(SSO_SESSION_RETRY_COUNTER, (counter + 1).toString());
    return true;
};
