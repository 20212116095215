import LoginState from '../util/LoginState';
import {
    getCurrentNumberOfScopes, getNumberOfScopesFromUserData, numberToDefaultLoginState, parseJwt, randomJitter, getAuthType,
} from '../util/Util';
import {
    INTERACTION_REQUIRED, LOGIN_DISABLED, LOGIN_REQUIRED, RETRY_TIMEOUT, SSO_EVENT,
} from '../util/Constants';
import { getAccessTokenCookie, getProfileCookieAsJSON, getVideoCookie } from '../gateway/Cookies';
import {
    clearSession,
    clearSessionScope,
    getSessionState,
    getSessionScope,
    isRetryRefresh,
    setSessionCurrentScopes,
    setSessionState,
    setSessionToError,
    setSessionToOk,
    shouldRetry,
    startRetryCounterIfNeeded,
    deleteProfile,
    clearChildLoginCode,
} from '../gateway/Storage';
import { debug, info } from '../util/Logger';
import { forceTokenRefresh, setRefreshIfPossible, getCurrentProfile } from './Refresh';
// eslint-disable-next-line import/no-cycle
import { tryLogin } from './Login';
/**
 * @typedef {import("./Login").cfg} cfg
 */

/**
 *
 * @param {number} currentState
 * @param {number} newState
 * @param {*} [userData]
 * @returns {boolean}
 */
export const isNewStateOrHasNewScopes = (currentState, newState, userData, sub) => currentState !== newState
    || (newState === LoginState.loggedIn && (getCurrentNumberOfScopes() !== getNumberOfScopesFromUserData(userData) || parseJwt(userData.token).sub !== sub));

/**
 *
 * @param {number} currentState
 * @param {string} message
 * @returns {boolean}
 */
const isLoginRequiredWithSessionPhoneScope = (currentState, message) => message.includes(LOGIN_REQUIRED)
    && LoginState.loggedIn === currentState && (getSessionScope() || '').indexOf('phone') !== -1;

/**
 *
 * @param {number} currentState
 * @param {string} message
 * @returns {boolean}
 */
const isInteractionRequiredWhileLoggedIn = (currentState, message) => message.includes(INTERACTION_REQUIRED) && LoginState.loggedIn === currentState;

/**
 *
 * @param {number} currentState
 * @param {string} errMessage
 * @returns {string|boolean}
 */
export function extraRequirements(currentState, errMessage) {
    if (isInteractionRequiredWhileLoggedIn(currentState, errMessage)) {
        info('Received interaction required while logged in - keeping us logged in and sending special interaction required to handlers.');
        return 'interaction_required_while_logged_in';
    }
    if (isLoginRequiredWithSessionPhoneScope(currentState, errMessage)) {
        info('Received login required while logged in with session phone scope - keeping us logged in and sending special login required.');
        return 'login_required_while_logged_in';
    }
    return false;
}

/**
 *
 * @param {string} err
 * @param {number} previousState
 */
export function getStateDetails(previousState, err) {
    const userData = getProfileCookieAsJSON();
    const videoToken = getVideoCookie();
    const accessToken = getAccessTokenCookie();
    const currentProfile = getCurrentProfile(userData);
    const state = getSessionState();
    const detail = { state, stateDetail: numberToDefaultLoginState(state) };
    if (userData) userData.id_token = accessToken;

    if (previousState) {
        detail.previousState = previousState;
    }

    if (err) {
        detail.error = err;
    } else {
        Object.assign(detail, { userData });
        Object.assign(detail, { videoToken });
        Object.assign(detail, { accessToken });
        Object.assign(detail, { currentProfile });
        Object.assign(detail, { authType: getAuthType()});
        setSessionCurrentScopes((userData || {}).scopes);
    }
    return detail;
}
/**
 * @param {string} err
 * @param {number} previousState
 * @param {function} [listener]
 * @fires ssoEvent
 */
export function fireStateChangeEvent(err, previousState, listener) {
    const detail = getStateDetails(previousState, err);
    /**
     * @event ssoEvent
     * @type {CustomEvent}
     * @property {number} state
     * @property {string} stateDetail
     * @property {string} [previousState]
     * @property {string} [error]
     * @property {*} [detail]
     */
    const event = new CustomEvent(SSO_EVENT, { detail });
    if (listener) {
        listener(event);
    } else {
        window.dispatchEvent(event);
    }
}

/**
 *
 * @param {cfg} config
 * @param {number} [retryTimeout]
 * @returns {function(number): void}
 */
export const retry = (config, retryTimeout) => (newState) => {
    startRetryCounterIfNeeded();
    setTimeout(() => {
        // if shouldRetry is incorrectly implemented, this can lead to login/refresh calls every three-or-so minutes
        if (shouldRetry()) {
            // reset state and session to make sure we actually do a retry
            setSessionState(newState);
            clearSession();

            if (isRetryRefresh()) {
                forceTokenRefresh(config.ssoPath);
            } else {
                tryLogin(config);
            }
        }
    }, retryTimeout || RETRY_TIMEOUT + randomJitter());
};


/**
 *
 * @param {number} newState
 * @param err
 */
function setState(newState, err, sub, forcedEvent) {
    debug('sub ... ', sub);
    const currentState = getSessionState();
    let errorForEvent = err;
    let extra = false;

    if (isNewStateOrHasNewScopes(currentState, newState, getProfileCookieAsJSON(), sub)) {
        debug(`Received new state: ${newState}`);
        switch (newState) {
            case LoginState.loggedIn:
                setSessionToOk();
                break;
            case LoginState.loggedOut:
                extra = extraRequirements(currentState, err);
                if (extra) {
                    errorForEvent = extra;
                } else {
                    retry(newState);
                    setSessionToError(err);
                }
                break;
            case LoginState.disabled:
                setSessionToError(err);
                break;
            default:
                setSessionToError();
        }
        if (!extra) {
            setSessionState(newState);
        }
        if (newState !== LoginState.loggedIn) {
            clearSessionScope();
        }
        fireStateChangeEvent(errorForEvent, currentState);
    } else if (forcedEvent) {
        fireStateChangeEvent(null, currentState);
    }
}

export function setStateToDisabled() {
    deleteProfile();
    setState(LoginState.disabled, LOGIN_DISABLED, null, true);
}

export function setStateToLoggedOut(err) {
    deleteProfile();
    setState(LoginState.loggedOut, err, null, true);
}

export function setStateToLoggedIn(config, sub, forcedEvent) {
    setRefreshIfPossible(config.ssoPath, config.autoRefreshOff)(() => setStateToLoggedOut('refresh failed'));
    setState(LoginState.loggedIn, null, sub, forcedEvent);
    clearChildLoginCode();
}
