import {
    FALLBACK_REFRESH_TIME,
    MAX_ALLOWED_REFRESH_TIME,
    HEADERS,
    MARGIN,
    SSO_EVENT,
    RequestMethod,
    PROFILE_TYPE,
    ONE_MINUTE_IN_MILLIS,
} from '../util/Constants';
import {
    deleteCookies, getVideoCookie, getAccessTokenCookie,
    getExpiryCookie,
} from '../gateway/Cookies';
import { request } from '../gateway/Request';
import LoginState from '../util/LoginState';
import {
    getLiveInteraction, clearLiveInteraction, setLiveInteraction, setRetryRefresh, storeProfile, getSessionState, noInteraction, retrieveProfile,
    setUserData,
    clearUserData,
    getUserData,
} from '../gateway/Storage';
import { debug } from '../util/Logger';
import {
    getMarketingId, parseJwt,
} from '../util/Util';
/**
 * @typedef {import("./Login").cfg} cfg
 */
let retriedNegativeDate = false; // or use closure?
let profile = null;
export const swtchProfile = (newProfile) => { profile = newProfile; storeProfile(newProfile); };
export const getProfile = () => profile;

const getKidsInteraction = () => {
    const userData = getUserData();
    if (!userData) return null;
    const prfl = retrieveProfile();
    if (!prfl) return null;
    return userData.subprofiles[prfl].interaction;
};
export const getCurrentProfile = (userData) => {
    const accessToken = getAccessTokenCookie();
    if (userData && accessToken) {
        const token = parseJwt(accessToken);
        const showAccessibility = userData.scopes && userData.scopes.split(',').includes('accessibility');
        if (token.type === PROFILE_TYPE.CHILD) {
            swtchProfile(token.sub);
            const interaction = getKidsInteraction();
            return {
                profileType: token.type,
                sub: token.sub,
                marketingId: getMarketingId(),
                ...showAccessibility ? { accessibility: userData.subprofiles[token.sub].accessibility } : {},
                interaction,
                username: userData.subprofiles[token.sub].username,
            };
        }
        return { profileType: token.type || 'main', marketingId: getMarketingId(), ...showAccessibility ? { accessibility: userData.accessibility } : {} };
    }
    return {
        profileType: PROFILE_TYPE.NONE, sub: null, marketingId: null, accessibility: null,
    };
};
export function getAuthType() {
    const accessToken = parseJwt(getAccessTokenCookie());
    return accessToken.auth || 'none';
}

export function fireEvent(profileSwitched) {
    const userData = getUserData();
    const videoToken = getVideoCookie();
    const accessToken = getAccessTokenCookie();
    const currentProfile = getCurrentProfile(userData);
    const detail = { state: LoginState.loggedIn, stateDetail: profileSwitched ? 'profileSwitched' : 'refresh' };
    if (userData) userData.id_token = accessToken;
    Object.assign(detail, { userData });
    Object.assign(detail, { videoToken });
    Object.assign(detail, { accessToken });
    Object.assign(detail, { currentProfile });
    Object.assign(detail, { authType: getAuthType() });

    const event = new CustomEvent(SSO_EVENT, { detail });
    window.dispatchEvent(event);
}

/**
 *
 * @param {boolean} autoRefreshOff
 * @param {number} timeUntilNextRefresh
 * @returns {boolean}
 */
export function canScheduleNextRefresh(autoRefreshOff, timeUntilNextRefresh) {
    return timeUntilNextRefresh && typeof timeUntilNextRefresh === 'number' && !autoRefreshOff;
}

/**
 *
 * @returns {null|number}
 */
export function getTimeUntilNextRefresh() {
    const accessTokenExpiry = parseJwt(getAccessTokenCookie()).exp * 1000;

    if (accessTokenExpiry) {
        const timeUntilNextRefresh = (accessTokenExpiry - new Date()) - MARGIN;

        if (timeUntilNextRefresh < 0 && !retriedNegativeDate) {
            retriedNegativeDate = true;
            return FALLBACK_REFRESH_TIME;
        }
        if (timeUntilNextRefresh < 0 && retriedNegativeDate) {
            return null;
        }
        if (timeUntilNextRefresh > 0) {
            retriedNegativeDate = false;
            return timeUntilNextRefresh > MAX_ALLOWED_REFRESH_TIME ? MAX_ALLOWED_REFRESH_TIME : timeUntilNextRefresh;
        }
    }
    return null;
}

export function loadUserData() {
    const accessToken = getAccessTokenCookie();
    return new Promise((resolve, reject) => {
        const basePath = parseJwt(accessToken).iss;
        const url = `${basePath}/userinfo`;
        const headers = { Authorization: `Bearer ${accessToken}` };
        request(RequestMethod.GET, url, headers, {}, true, false)
            .then((result) => {
                const jsonresult = JSON.parse(result);
                setUserData(jsonresult);
                deleteCookies();
                resolve(jsonresult);
            })
            .catch((error) => {
                debug('error', error);
                clearUserData();
                reject(error);
            });
    });
}

export function retrieveUserData() {
    return new Promise((resolve, reject) => {
        const localUserData = getUserData() || {};
        if (localUserData.given_name) {
            resolve(localUserData);
        } else {
            (async () => {
                await loadUserData()
                    .then(userData => resolve(userData))
                    .catch(error => reject(error));
            })();
        }
    });
}

/**
 * @callback refreshCallback
 * @param {Error|null|string} error
 * @param {boolean} [result]
 */
/**
 * @param {string} refreshUrl
 * @param {refreshCallback} cb
 */
export function attemptTokenRefresh(refreshUrl, cb) {
    if (!getUserData() && !getExpiryCookie()) { // TODO: expiryCookie check can be removed on 25/09/2025
        cb(new Error('not logged in'));
        return;
    }
    request(RequestMethod.GET, refreshUrl, HEADERS, profile ? `subprofile=${profile}` : {}, true, true)
        .then(async () => {
            await loadUserData();
        })
        .then(() => {
            cb(null, true);
        })
        .catch((e) => {
            deleteCookies();
            clearUserData();
            storeProfile();
            profile = null;
            cb(e);
        });
}

/**
 *
 * @returns {boolean}
 */
export function isTokenExpired() {
    const expiry = parseJwt(getAccessTokenCookie()).exp;
    if (!expiry) return true;
    try {
        return (parseInt(expiry, 10) * 1000) < Date.now() - ONE_MINUTE_IN_MILLIS;
    } catch (e) {
        return true; // in case we accidentally create cookies with unparsable dates
    }
}

/**
 *
 * @param {string} refreshUrl
 * @param {string[]} scope
 * @param {string[]} addedScope
 * @param {refreshCallback} cb
 */
export function attemptTokenRefreshIfNeeded(refreshUrl, scope, addedScope, cb) {
    if (scope && addedScope) {
        if (!addedScope.every(sc => scope.includes(sc))) {
            setTimeout(() => {
                cb('added_scope');
            }, 0);
            return;
        }
    }
    if (isTokenExpired()) {
        attemptTokenRefresh(refreshUrl, cb);
    } else {
        retrieveUserData().then(() => {
            cb(null, false);
        });
    }
}

export const refreshUserData = config => (cb = () => { }) => {
    const callback = (err, result) => {
        cb(err, result);
        fireEvent(false);
    };
    if (isTokenExpired()) {
        attemptTokenRefresh(`/${config.ssoPath}/refresh`, callback);
    } else {
        (async () => {
            await loadUserData();
            cb(null, true);
            fireEvent(false);
        })();
    }
};


const getAccessToken = config => (cb) => {
    attemptTokenRefreshIfNeeded(`/${config.ssoPath}/refresh`, null, null, (err) => {
        if (err) {
            return cb(null);
        }
        return cb(getAccessTokenCookie());
    });
};
export const profileSwitchAllowed = config => (pincode, cb = () => { }) => {
    const userData = getUserData();
    const currentProfile = getCurrentProfile(userData);
    if (currentProfile.profileType === PROFILE_TYPE.MAIN) { cb(null, true); return; }
    getAccessToken(config)((token) => {
        const pinobject = pincode ? JSON.stringify({ pincode }) : null;
        request(RequestMethod.POST, `${config.ssoPath}/profileswitch`, { Authorization: `Bearer ${token}` }, pinobject)
            .then((result) => {
                const { allowed } = JSON.parse(result);
                cb(null, allowed);
            })
            .catch((error) => {
                debug('fout: ', error);
                cb({ ...JSON.parse(error.message || '{"Error":""}'), code: error.cause });
            });
    });
};

export const getLiveInteractionToken = config => (cb = () => { }) => {
    debug('getLiveInteractionToken');
    if (noInteraction()) {
        debug('no interaction');
        setTimeout(() => {
            cb({ Error: 'temporary disabled' });
        }, 0);
    } else {
        const interactionToken = getLiveInteraction();
        const cmptime = new Date();
        cmptime.setMinutes(cmptime.getMinutes() + 5);
        const callback = (token) => {
            cb(null, { liveInteractionToken: token.refreshToken, expiresAt: token.expiresAt });
        };
        const doRequest = (token) => {
            const auth = token ? { Authorization: `Bearer ${token}` } : undefined;
            request(RequestMethod.POST, `${config.ssoPath}/live_interaction`, auth)
                .then((result) => {
                    debug('new interaction');
                    const parsed = JSON.parse(result);
                    setLiveInteraction(parsed);
                    callback(parsed);
                }).catch((error) => {
                    debug('error from live_interaction endpoint :', error);
                    cb({ Error: error.message });
                });
        };
        if (interactionToken && new Date(interactionToken.expiresAt) > cmptime && getSessionState() > 0) {
            setTimeout(() => {
                debug('previous interaction');
                callback(interactionToken);
            }, 0);
        } else {
            clearLiveInteraction();
            const state = getSessionState();
            if (state === LoginState.loggedOut || state === LoginState.disabled) {
                doRequest();
            } else {
                getAccessToken(config)((token) => {
                    doRequest(token);
                });
            }
        }
    }
};
/**
 *
 * @param {string} ssoPath
 * @param {boolean} autoRefreshOff
 * @returns {function(function): void}
 */
export const setRefreshIfPossible = (ssoPath, autoRefreshOff) => (setLoggedOut) => {
    let timeUntilNextRefresh = getTimeUntilNextRefresh();
    // eslint-disable-next-line no-use-before-define
    const nextRefresh = rf => setTimeout(scheduleRefresh, rf);

    const scheduleRefresh = () => {
        attemptTokenRefresh(`/${ssoPath}/refresh`, (err) => {
            timeUntilNextRefresh = getTimeUntilNextRefresh();

            if (err) {
                debug(`Refresh gave back an error: ${err}`);
                setRetryRefresh();
                setLoggedOut();
            } else {
                fireEvent();
            }

            if (canScheduleNextRefresh(autoRefreshOff, timeUntilNextRefresh)) {
                nextRefresh(timeUntilNextRefresh);
            }
        });
    };

    if (canScheduleNextRefresh(autoRefreshOff, timeUntilNextRefresh)) {
        debug(`Time until next refresh: ${timeUntilNextRefresh}`);
        setTimeout(scheduleRefresh, timeUntilNextRefresh);
    }
};

/**
 *
 * @param {cfg} config
 * @param profileSwitched
 * @returns {(function(refreshCallback?): void)|*}
 */
export const forceTokenRefresh = (config, profileSwitched) => (callback = () => {
}) => {
    attemptTokenRefresh(`/${config.ssoPath}/refresh`, (error, result) => {
        if (!error) {
            fireEvent(profileSwitched);
        }
        callback(error, result);
    });
};
