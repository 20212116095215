import {
    getAccessTokenCookie, getProfileCookieAsJSON, getScopesWith, getVideoCookie,
} from '../gateway/Cookies';
import { request } from '../gateway/Request';
import {
    DISABLE_ANALYTICS, ENABLE_ANALYTICS, HEADERS, RequestMethod, ID_TOKEN_HINT_PARAM, LOGGED_OUT,
} from '../util/Constants';
import {
    clearSessionStorage, getSessionScope, hasSession, isLoginDisabled, setSessionScope, deleteProfile, setSessionInteractionRequired, clearLiveInteraction, setNoInteraction,
} from '../gateway/Storage';
import { debug } from '../util/Logger';
import { addResumePageQueryParam, loginAnalytics, parseJwt } from '../util/Util';
import { attemptTokenRefresh, attemptTokenRefreshIfNeeded, fireEvent } from './Refresh';
import { manualLoginFor } from './LoginCalls';
// eslint-disable-next-line import/no-cycle
import {
    getStateDetails, setStateToDisabled, setStateToLoggedIn, setStateToLoggedOut,
} from './State';

/**
 * @typedef cfg
 * @property {string} environment
 * @property {function} resumePage
 * @property {boolean} autoRefreshOff
 * @property {boolean} autoLoginOff
 * @property {string} ssoPath
 * @property {boolean} loadWidget
 */

/**
 * @param {string} url
 * @param {cfg} config
 * @returns void
 */
function autoLoginCall(config, url, callback, isRepeat) {
    let error = false;
    const userData = getProfileCookieAsJSON();
    const accessToken = getAccessTokenCookie();
    let sub = false;
    let canCallback = true;
    if (userData && accessToken) {
        const token = parseJwt(accessToken) || {};
        ({sub} = token);
    }
    request(RequestMethod.GET, url, HEADERS, {}, true, true)
        .then(() => {
            try {
                setStateToLoggedIn(config, sub);
                loginAnalytics(ENABLE_ANALYTICS);
            } catch (cookieError) {
                error = cookieError.message;
                setStateToLoggedOut(error);
            }
        })
        .catch((requestError) => {
            error = requestError.message;
            if (error.includes('interaction_required') && !isRepeat) {
                setSessionInteractionRequired(JSON.stringify(config.addedScope));
                canCallback = false;
                const urlObj = new URL(url, window.location.href);
                urlObj.searchParams.set('scope', getScopesWith());
                autoLoginCall(config, urlObj.href, callback, true);
            } else {
                setStateToLoggedOut(error);
            }
        }).finally(() => {
            if (canCallback && callback) {
                callback(getStateDetails(null, error));
            }
        });
}

/**
 * @param {cfg} config
 * @param {string} [idTokenHint]
 * @returns void
 */
export const tryAutoLogin = (config, idTokenHint, callback) => {
    const scope = getScopesWith(config.addedScope);
    const params = new URLSearchParams();
    if (scope) params.set('scope', scope);
    if (idTokenHint) params.set('id_token_hint', idTokenHint);
    const autologinUrl = `/${config.ssoPath}/autologin?${params.toString()}`;
    autoLoginCall(config, autologinUrl, callback);
};

/**
 * @param {cfg} config
 * @returns {function(idTokenHint:string):void}
 */
export const tryAutoLoginWithIdentityToken = config => (idTokenHint, callback) => {
    tryAutoLogin(config, idTokenHint, callback);
};
/**
 *
 * @param {cfg} config
 * @returns {function(): void}
 */
export const tryLogin = config => (forcedEvent, callback) => {
    const urlsp = new URLSearchParams(window.location.search);
    const idTokenHint = urlsp.get(ID_TOKEN_HINT_PARAM);
    if (idTokenHint) {
        urlsp.delete(ID_TOKEN_HINT_PARAM);
        window.history.replaceState(window.history.state, document.title, `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlsp.toString()}${window.location.hash}`);
        tryAutoLogin(config, idTokenHint, callback);
    } else if (isLoginDisabled()) {
        setStateToDisabled();
        if (callback) callback();
    } else {
        const userData = getProfileCookieAsJSON() || {};
        attemptTokenRefreshIfNeeded(`/${config.ssoPath}/refresh`, userData.scopes, config.addedScope, (err) => {
            if (err) {
                debug(`Try login gave back an error ${err}`);
                if (!hasSession() || err === 'added_scope') {
                    debug('And we do not have a session, so trying autologin');
                    tryAutoLogin(config, null, callback);
                } else {
                    setStateToLoggedOut(err);
                    if (callback) callback();
                }
            } else if (getProfileCookieAsJSON()) {
                debug('Try login succeeded and we have user data');
                setStateToLoggedIn(config, forcedEvent);
                loginAnalytics(ENABLE_ANALYTICS);
                if (callback) callback();
            } else if (!hasSession()) {
                debug('No session - trying autologin');
                tryAutoLogin(config, null, callback);
            } else {
                debug('Default - setting state to logged out');
                setStateToLoggedOut(LOGGED_OUT);
                if (callback) callback();
            }
        });
    }
};

/**
 * @callback idTokenCallback
 * @param {string|null} value
 */
/**
 * @param {idTokenCallback} cb
 * @returns {*}
 */
const resolveWithUserData = cb => cb(getAccessTokenCookie());

/**
 *
 * @param {cfg} config
 * @returns {function(idTokenCallback): *}
 */
export const getIdToken = config => (cb) => {
    attemptTokenRefreshIfNeeded(`/${config.ssoPath}/refresh`, null, null, (err) => {
        if (err) {
            manualLoginFor(config)(err, null, getSessionScope());
            return cb(null);
        }
        return resolveWithUserData(cb);
    });
};
/**
 *
 * @param {cfg} config
 * @returns {function(idTokenCallback): void}
 */
export const getNewIdToken = config => (cb) => {
    attemptTokenRefresh(`/${config.ssoPath}/refresh`, (err) => {
        if (err) {
            return cb(null);
        }
        fireEvent();
        return resolveWithUserData(cb);
    });
};

/**
 *
 * @param {cfg} config
 * @returns {function(idTokenCallback): void}
 */
export const getAccessToken = config => (cb) => {
    attemptTokenRefreshIfNeeded(`/${config.ssoPath}/refresh`, null, null, (err) => {
        if (err) {
            return cb(null);
        }
        return cb(getAccessTokenCookie());
    });
};

/**
 *
 * @param {cfg} config
 * @returns {function(idTokenCallback): void}
 */
export const getVideoToken = config => (cb) => {
    attemptTokenRefreshIfNeeded(`/${config.ssoPath}/refresh`, null, null, (err) => {
        if (err) {
            return cb(null);
        }
        return cb(getVideoCookie());
    });
};

/**
 *
 * @param {cfg} config
 * @returns {function(string|string[]): void}
 */
export const addScopeSilent = config => (customScope) => {
    clearSessionStorage();
    setSessionScope(customScope);

    getIdToken(config)((idToken) => {
        const scope = getScopesWith(customScope);
        const autologinUrl = `/${config.ssoPath}/autologin?scope=${scope}${idToken ? `&id_token_hint=${idToken}` : ''}`;
        autoLoginCall(config, autologinUrl, null, true);
    });
};

/**
 *
 * @param {cfg} config
 * @returns {function(): void}
 */
export const logout = config => () => {
    setStateToDisabled();
    loginAnalytics(DISABLE_ANALYTICS);
    deleteProfile();
    setNoInteraction();
    clearLiveInteraction();
    window.location.href = addResumePageQueryParam(`/${config.ssoPath}/logout`, config.resumePage, window.location.href);
};

export const createSubProfile = config => (cb) => {
    getAccessToken(config)((token) => {
        const url = `/${config.ssoPath}/kinderprofielen`;
        const headers = {Authorization: `Bearer ${token}`};
        request(RequestMethod.POST, url, headers, {}, true, true)
        .then((result) => {
            const jsonresult = JSON.parse(result);
            cb(jsonresult);
        }).catch((error) => {
            debug('error', error);
            cb(null);
        });
    });
};
