
import {
    getChildLoginCode, clearChildLoginCode, setChildLoginCode, clearSessionStorage,
    setNoInteraction, clearLiveInteraction, retrieveProfile, getSessionState,
} from '../gateway/Storage';
import { request } from '../gateway/Request';
import { RequestMethod, PROFILE_TYPE } from '../util/Constants';
import { error, debug } from '../util/Logger';
import { attemptTokenRefreshIfNeeded, getCurrentProfile } from './Refresh';
import { getAccessTokenCookie, getProfileCookieAsJSON } from '../gateway/Cookies';
import LoginState from '../util/LoginState';

const getNewChildLoginCodeIfNeeded = (config, geoLocation) => new Promise((resolve, reject) => {
    const code = getChildLoginCode();
    if (code && (new Date(code.expiration_time) > Date.now())) {
        resolve(code.login_code);
    } else {
        clearChildLoginCode();
        request(RequestMethod.POST, `/${config.ssoPath}/subprofileauth`, {'Content-Type': 'application/x-www-form-urlencoded'}, {geo_location: geoLocation || 'BE'}, true, true)
        .then((rawData) => {
            const data = JSON.parse(rawData);
            setChildLoginCode(data);
            resolve(data.login_code);
        }).catch((e) => {
            error('request error', e);
            reject(e);
        });
    }
});
const getAccessToken = config => (cb) => {
    attemptTokenRefreshIfNeeded(`/${config.ssoPath}/refresh`, null, null, (err) => {
        if (err) {
            return cb(null);
        }
        return cb(getAccessTokenCookie());
    });
};
export const loginAsChild = config => async (geoLocation) => {
    await getNewChildLoginCodeIfNeeded(config, geoLocation)
    .then((loginCode) => {
        clearSessionStorage();
        setNoInteraction();
        clearLiveInteraction();
        window.location.href = `/${config.ssoPath}/login?login_code=${loginCode}`;
    })
    .catch((e) => {
        error('loginAsChild error', e);
        clearChildLoginCode();
    });
};

export const createSubProfile = config => (cb) => {
    getAccessToken(config)((token) => {
        const url = `/${config.ssoPath}/kinderprofielen`;
        const headers = {Authorization: `Bearer ${token}`};
        request(RequestMethod.POST, url, headers, {}, true, true)
        .then((result) => {
            const jsonresult = JSON.parse(result);
            cb(jsonresult);
        }).catch((err) => {
            debug('error', err);
            cb(null);
        });
    });
};

export const profileSwitchAllowed = config => (pincode, cb = () => {}) => {
    const userData = getProfileCookieAsJSON();
    const currentProfile = getCurrentProfile(userData);
    if (currentProfile.profileType === PROFILE_TYPE.MAIN) { cb(null, true); return; }
    getAccessToken(config)((token) => {
        const pinobject = pincode ? JSON.stringify({pincode}) : null;
        request(RequestMethod.POST, `${config.ssoPath}/profileswitch`, {Authorization: `Bearer ${token}`}, pinobject)
            .then((result) => {
                const {allowed} = JSON.parse(result);
                cb(null, allowed);
            })
            .catch((err) => {
                debug('fout: ', err);
                cb({...JSON.parse(err.message || '{"Error":""}'), code: err.cause});
            });
    });
};

export const getKidsInteraction = () => {
    const userData = getProfileCookieAsJSON();
    if (!userData) return null;
    const profile = retrieveProfile();
    if (!profile) return null;
    return userData.subprofiles[profile].interaction;
};

export const kidRequestsInteraction = config => (cb = () => {}) => {
    if (getSessionState() === LoginState.loggedOut) return cb(null, null);
    const hasInteraction = getKidsInteraction();
    if (hasInteraction === null || hasInteraction === true) return cb(null, null);
    getAccessToken(config)((token) => {
        request(RequestMethod.POST, `${config.ssoPath}/kids_interaction`, {Authorization: `Bearer ${token}`})
            .then((result) => {
                debug(result);
                cb(null, result);
            })
            .catch((err) => {
                debug('fout: ', err);
                cb({...JSON.parse(err.message || '{"Error":""}'), code: err.cause});
            });
    });
    return null;
};
